import React from 'react'
import { Grid, makeStyles, Typography, Hidden } from '@material-ui/core'
import generalCss from '../../styles/generalCss'
import ImageComponent from '../GeneralComponents/ImageComponent'
import LinkButton from '../GeneralComponents/Buttons/LinkButton'
import RichTextComponent from '../RichTextComponent'
import Routes from '../../utilities/Routes'
import LinkComponent from '../GatsbyComponents/LinkComponent'
import RightArrow from '../SVGs/RightArrow'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        borderRadius: '0px',
        [theme.breakpoints.up('md')]: {
            backgroundColor: generalCss.primaryFadedBgColor,
            borderRadius: "1.5rem",
            overflow: 'hidden',
        }
    },
    imageStyles: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        minHeight: "200px",
        borderRadius: "12px",
        [theme.breakpoints.up("md")]: {
            minHeight: "400px",
            borderRadius: "0px"
        }
    },
    textContainer: {
        flex: 1,
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: "50px 40px"
        }

    }
}))

function ProductCardItem({ data, flexDirection = 'row', containerFlexDirection = 'row' }) {
    const classes = useStyles()
    const { coverImage, title, showDetailPage, shortDetails, slug } = data
    return <Grid container alignItems='stretch' spacing={2} direction={flexDirection}>
        <Grid item xs={12} md={5}>
            <ImageComponent
                src={!!coverImage ? coverImage.url : ''}
                alt={!!coverImage ? coverImage.alternativeText : ""}
                className={classes.imageStyles}
            />
        </Grid>
        <Grid item xs={12} md={7} style={{ display: 'flex' }}>
            <div className={classes.textContainer}>
                <Grid container direction={containerFlexDirection} justifyContent='space-between' style={{ height: "100%" }} spacing={2}>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {
                                    (showDetailPage && !!slug) ?
                                        <LinkComponent path={`${Routes.products}/${slug}`}>
                                            <Typography variant='h4' color='textSecondary' >{title}</Typography>
                                        </LinkComponent>
                                        :
                                        <Typography variant='h4' color='textSecondary' >{title}</Typography>
                                }
                            </Grid>
                            {
                                !!shortDetails &&
                                <Grid item xs={12}>
                                    <RichTextComponent text={shortDetails} />
                                </Grid>
                            }

                        </Grid>
                    </Grid>
                    {
                        showDetailPage && !!slug &&
                        <Grid item >
                            <LinkButton
                                href={`${Routes.products}/${slug}`}
                            >
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item>
                                        Learn More
                                    </Grid>
                                    <Grid item style={{ display: 'flex' }}>
                                        <RightArrow />
                                    </Grid>
                                </Grid>
                            </LinkButton>
                        </Grid>
                    }
                </Grid>
            </div>

        </Grid>
    </Grid>
}

function ProductCard({ data, index }) {
    const classes = useStyles()
    return (
        <div id={data.slug} className={classes.root}>
            <Hidden mdUp implementation='css'>
                <ProductCardItem data={data} flexDirection={'row'} containerFlexDirection={'row'} />
            </Hidden>
            <Hidden smDown implementation='css'>
                <ProductCardItem data={data} flexDirection={index % 2 === 0 ? 'row' : 'row-reverse'} containerFlexDirection='column' />
            </Hidden>
        </div>
    )
}
export default ProductCard